// extracted by mini-css-extract-plugin
export var backgroundImage = "contactHero-module--backgroundImage--00fff";
export var contactBottomBlock = "contactHero-module--contactBottomBlock--06ec9";
export var contactLink = "contactHero-module--contactLink--19c59";
export var contactSection = "contactHero-module--contactSection--4a827";
export var contacts = "contactHero-module--contacts--12527";
export var divider = "contactHero-module--divider--e10eb";
export var sectionDesc = "contactHero-module--sectionDesc--f7bf4";
export var sectionTitle = "contactHero-module--sectionTitle--a17d8";
export var sectionWrapper = "contactHero-module--sectionWrapper--12875";
export var text = "contactHero-module--text--1dcc5";